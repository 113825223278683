import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import PostPreview from '../components/PostPreview'
import CategoryList from '../components/CategoryList'
import Seo from 'gatsby-plugin-wpgraphql-seo';

// markup
export default function ContentHub({ data }) {
	
  const page = data.allWpPage.nodes[0];
  const posts = data.allWpPost?.nodes;

  const printFilteredArticles = (category, posts, limit=3) => {
	
	return posts.reduce((result, post, i) => {
	  const exists = post.categories?.nodes?.length && post.categories.nodes.some(c => (c.name === category));
      if (exists && result.length < limit) {
        result.push(<PostPreview key={`post${i}`} post={post} />);
      }
      return result;
    }, []);
  }

  const otherCategories = [
	{title: 'Documents', link: '/category/documents/'}, 
	{title: 'Guides', link: '/category/guides/'}, 
	{title: 'Case Studies', link: '/category/case-studies/'},
	{title: 'Webinars', link: '/category/webinars/'}
  ];

  return (
    <Layout className="content-hub">	
      <Seo post={page} />
	  <div className="page-widgets">
	    <Hero widget={{layout : 'mini'}} />
	  </div>
	  <CategoryList activeCategory={false} />
	  <article>
		<header className="internal">
		  <h1 className="visuallyhidden">Featured Articles</h1>
		</header>
			
		<section className="blog">
		  <div className="internal">
		    <h2>Blog</h2>
					
			<div className="articles">
						
			  {printFilteredArticles('Blog', posts, 4)}
						
			</div>
		  </div>
		</section>
			
		{otherCategories.map((item, i) => {
			const articles = printFilteredArticles(item.title, posts);
			if (articles.length) {
				return(
					<section key={`category${i}`} className="default">
						<div className="internal">
							<h2>{item.title}</h2>
							
						    <div className="articles">								
					        	{articles}								
						    </div>
							<p className="more"><Link to={item.link}>See more <span>{item.title}</span></Link></p>
					    </div>							
					</section>
				);
			}
			return null;
		})}
			
	  </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        ...PageSEOFragment
      }
    }
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        ...PostFragment
      }
    }
  }
`
